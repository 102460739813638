import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectTiempoTrabajado (Vue, idempleado, filter, sorter, page) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idempleado', idempleado)
      .addGT('estado', 0)
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (filter.idparteTrabajo.value) {
      apiFilter.addILike(filter.idparteTrabajo.field, filter.idparteTrabajo.value)
    }
    if (filter.ttarea.value && filter.ttarea.value.length > 0) {
      apiFilter.addIn(filter.ttarea.field, filter.ttarea.value)
    }
    if (filter.ttiempoTrabajado.value && filter.ttiempoTrabajado.value.length > 0) {
      apiFilter.addIn(filter.ttiempoTrabajado.field, filter.ttiempoTrabajado.value)
    }
    if (filter.esDesplazamiento.value) {
      apiFilter.addExact(filter.esDesplazamiento.field, filter.esDesplazamiento.value)
    }
    const resp = await Vue.$api.call('tiempoTrabajado.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectTiempoTrabajadoRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idtiempo_trabajado', pks)
    const resp = await Vue.$api.call('tiempoTrabajado.select', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteTiempoTrabajado (Vue, idtiempo_trabajado) {
    return await Vue.$api.call('tiempoTrabajado.delete', { idtiempo_trabajado })
  },
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({
      name: 'selectTtiempoTrabajado',
      method: 'ttiempoTrabajado.select',
      params: { filter: apiFilterEstadoActivo }
    })
    apiCalls.push({
      name: 'selectTtarea',
      method: 'ttarea.select',
      params: { filter: apiFilterEstadoActivo }
    })
    return await Vue.$api.batchCall(apiCalls)
  },
}
